<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">General</span>
      </template>

      <account-setting-general
        :user-data="userData"
        :reset-data="resetUserData"
        @saveChanges="saveChanges($event);"
      />
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Change Password</span>
      </template>

      <account-setting-password
        :user-data="userData"
        @saveChanges="changePassword($event);"
      />
    </b-tab>
    <!--/ change password tab -->
    <!--
    <!-- info -/->
    <b-tab>

      <!-- title -/->
      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Information</span>
      </template>

      <account-setting-information
        v-if="options.info"
        :information-data="options.info"
      />
    </b-tab>

    <!-- social links -/->
    <b-tab>

      <!-- title -/->
      <template #title>
        <feather-icon
          icon="LinkIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Social</span>
      </template>

      <account-setting-social
        v-if="options.social"
        :social-data="options.social"
      />
    </b-tab>

    <!-- notification -/->
    <b-tab>

      <!-- title -/->
      <template #title>
        <feather-icon
          icon="BellIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Notifications</span>
      </template>

      <account-setting-notification
        v-if="options.notification"
        :notification-data="options.notification"
      />
    </b-tab> -->
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line import/no-cycle
import { getUserData } from '@/auth/utils'
import AccountSettingGeneral from './AccountSettingGeneral.vue'
import AccountSettingPassword from './AccountSettingPassword.vue'
import userStoreModule from '../user/userStoreModule'
// import AccountSettingInformation from './AccountSettingInformation.vue'
// import AccountSettingSocial from './AccountSettingSocial.vue'
// import AccountSettingNotification from './AccountSettingNotification.vue'

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword,
    // AccountSettingInformation,
    // AccountSettingSocial,
    // AccountSettingNotification,
  },
  data() {
    return {
      options: {},
    }
  },
  beforeCreate() {
    this.$http.get('/account-setting/data').then(res => { this.options = res.data })
  },
  setup() {
    const user = getUserData() // JSON.parse(localStorage.getItem('userData'))
    const userData = ref(null)
    const resetUserData = ref(null)
    const USER_APP_STORE_MODULE_NAME = 'app-user'
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    onMounted(() => {
      store.dispatch('app-user/fetchUser', { id: user.id })
        .then(response => {
          userData.value = response.data
          resetUserData.value = JSON.parse(JSON.stringify(response.data))
        }).catch(error => {
          if (error.response.status === 404) {
            userData.value = undefined
          }
        })
    })

    return {
      userData,
      resetUserData,
    }
  },
  methods: {
    changePassword(event) {
      console.log(event)
      /* store.dispatch('app-user/updateUser', {
        userData: this.userData,
        permissions: this.currentRole,
      })
        .then(response => {
          this.$router.go(this.$router.currentRoute)
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred while saving the changes',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }) */
    },
    saveChanges(user) {
      console.log(user)
      store.dispatch('app-user/updateUser', {
        userData: user,
      })
        .then(response => {
          this.$router.go(this.$router.currentRoute)
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred while saving the changes',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>
